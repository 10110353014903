<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <button
                v-if="isEditButtonVisible"
                type="button"
                class="btn btn-success"
                @click="goToEdit()"
              >
                <i class="fa fa-edit "></i> {{ $t('buttons.edit') }}
              </button>
              <button
                v-if="isSaveButtonVisible"
                type="button"
                class="btn btn-success"
                @click="save"
              >
                <i class="fa fa-save "></i> {{ $t('buttons.save') }}
              </button>
              <app-button-delete v-if="isDeleteButtonVisible" @deleteRecord="deleteRecord" />
              <app-button-close route-name="contentBlock_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <section class="content form-horizontal">
        <div class="row">
          <div class="col-lg-6">
            <div class="card card-body">
              <div class="row">
                <div class="col-lg-6">
                  <h3 class="form-edit-header">{{ $t('contentBlock.main_settings') }}</h3>
                </div>
                <div class="col-lg-6 text-right">
                  <label for="contentBlock_type" class="m-r-10">{{ $t('contentBlock.type') }}</label>
                  <label
                    v-for="(contentBlockType, index) in contentBlockTypeValues"
                    :key="`item-${index}`"
                    class="btn btn-secondary m-r-5"
                    :class="{ 'active': contentBlock.type === contentBlockType.id }"
                  >
                    <input
                      type="radio"
                      v-model="contentBlock.type"
                      :value="contentBlockType.id"
                      :data-test="'type_' + contentBlock.id"
                      :disabled="disabled"
                    >
                    {{ contentBlockType.title }}
                  </label>
                </div>
              </div>
              <input type="hidden" v-model="contentBlock.id">
              <div class="form-group">
                <app-checkbox
                  v-model="contentBlock.hidden"
                  id="contentBlock_hidden"
                  :label="$t('contentBlock.hidden')"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <app-input
                v-model="contentBlock.title"
                @blur="$v.contentBlock.title.$touch()"
                :error="$v.contentBlock.title.$error"
                id="contentBlock_title"
                :label="$t('contentBlock.title')"
                :disabled="disabled"
              >
              </app-input>
              <app-input
                v-model="contentBlock.identifier"
                @blur="$v.contentBlock.identifier.$touch()"
                :error="$v.contentBlock.identifier.$error"
                id="contentBlock_identifier"
                :label="$t('contentBlock.identifier')"
                :disabled="disabled"
              >
              </app-input>
              <app-select
                v-model="contentBlock.site"
                :options="sites"
                option-value="name"
                id="contentBlock_site"
                :label="$t('contentBlock.site')"
                :disabled="disabled"
              >
              </app-select>
              <app-select
                v-model="contentBlock.category"
                :options="contentBlockCategories"
                id="contentBlock_category"
                :label="$t('contentBlock.category')"
                :disabled="disabled"
              >
              </app-select>
              <app-input
                type="number"
                v-model="contentBlock.numberOfItems"
                id="contentBlock_numberOfItems"
                :label="$t('contentBlock.number_of_items')"
                :disabled="disabled"
              >
              </app-input>
              <div class="row">
                <div class="col-lg-3">
                  <app-input
                    type="number"
                    v-model="contentBlock.position"
                    id="contentBlock_position"
                    :label="$t('contentBlock.position')"
                    :disabled="disabled"
                  >
                  </app-input>
                </div>
                <div class="col-lg-3">
                  <app-input
                    type="number"
                    v-model="contentBlock.displayPosition"
                    id="contentBlock_displayPosition"
                    :label="$t('contentBlock.display_position')"
                    :disabled="disabled"
                  >
                  </app-input>
                </div>
                <div class="col-lg-3">
                  <app-input
                    v-model="contentBlock.disabledPositions"
                    id="contentBlock_disabledPositions"
                    :label="$t('contentBlock.disabled_positions')"
                    :disabled="disabled"
                  >
                  </app-input>
                </div>
                <div class="col-lg-3">
                  <app-input
                    v-model="contentBlock.fallbackPositions"
                    id="contentBlock_fallbackPositions"
                    :label="$t('contentBlock.fallback_positions')"
                    :disabled="disabled"
                  >
                  </app-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="[contentBlockTypes.article, contentBlockTypes.videoArticle].includes(contentBlock.type)">
            <div class="card card-body">
              <h3 class="form-edit-header">{{ $t('contentBlock.fallback_settings') }}</h3>
              <div class="form-group">
                <app-checkbox
                  v-model="contentBlock.externalFallback"
                  id="contentBlock_externalFallback"
                  :label="$t('contentBlock.external_fallback')"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.internalFallback"
                  id="contentBlock_internalFallback"
                  :label="$t('contentBlock.internal_fallback')"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <app-select
                v-model="contentBlock.fallbackSite"
                :options="sites"
                id="contentBlock_falbackSite"
                :label="$t('contentBlock.fallback_site')"
                @change="loadMainRubricsBySite"
                :disabled="disabled"
              >
              </app-select>
              <div>
                <label>{{ $t('contentBlock.fallback_rubrics') }}</label>
                <app-multi-select
                  :value="contentBlock.fallbackRubrics ? contentBlock.fallbackRubrics.map(id => ({ id: id, title: rubricTitleById(id) })): []"
                  @input="(value) => contentBlock.fallbackRubrics = value ? value.map(v => v.id) : []"
                  :options="rubrics"
                  label="title"
                  track-by="id"
                  :limit="100"
                  id="contentBlock_fallbackRubrics"
                  :disabled="disabled"
                >
                </app-multi-select>
              </div>
              <div>
                <label>{{ $t('contentBlock.fallback_tags') }}</label>
                <app-multi-select
                  :value="contentBlock.fallbackTags ? contentBlock.fallbackTags.map(id => ({ id: id, title: tagTitleById(id) })): []"
                  @input="onFallbackTagsInput"
                  :options="tags"
                  label="title"
                  track-by="id"
                  :preselect-first="true"
                  :loading="isLoading"
                  :internal-search="false"
                  :options-limit="300"
                  :limit="100"
                  :max-height="600"
                  :show-no-results="false"
                  @search-change="findTag"
                  id="contentBlock_fallbackTags"
                  :disabled="disabled"
                >
                </app-multi-select>
              </div>
              <div class="form-group">
                <label>{{ $t('contentBlock.fallback_categories') }}</label>
                <app-multi-select
                  :value="contentBlock.fallbackCategories ? contentBlock.fallbackCategories.map(id => ({ id: id, title: categoryTitleById(id) })): []"
                  @input="(value) => contentBlock.fallbackCategories = value ? value.map(v => v.id) : []"
                  :options="categories"
                  label="title"
                  track-by="id"
                  id="contentBlock_fallbackCategories"
                  :disabled="disabled"
                >
                </app-multi-select>
              </div>
              <div class="form-group">
                <p><strong>{{ $t('contentBlock.fallback_article_types') }}</strong></p>
                <app-checkbox
                  v-model="contentBlock.fallbackArticleType.article"
                  id="contentBlock_articleType_article"
                  label="Article"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.fallbackArticleType.external"
                  id="contentBlock_articleType_external"
                  label="External"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.fallbackArticleType.video"
                  id="contentBlock_articleType_video"
                  label="Video"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.fallbackArticleType.quiz"
                  id="contentBlock_articleType_quiz"
                  label="Quiz"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.fallbackArticleType.photostory"
                  id="contentBlock_articleType_photostory"
                  label="Photostory"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <div class="form-group">
                <p><strong>{{ $t('contentBlock.fallback_article_flags') }}</strong></p>
                <app-checkbox
                  v-model="contentBlock.fallbackFlag.video"
                  id="contentBlock_flag_video"
                  label="Video"
                  :disabled="disabled"
                >
                </app-checkbox>
                <app-checkbox
                  v-model="contentBlock.fallbackFlag.imageGallery"
                  id="contentBlock_flag_imageGallery"
                  label="Image gallery"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <div class="form-group">
                <p><strong>{{ $t('contentBlock.fallback_article_settings') }}</strong></p>
                <app-checkbox
                  v-model="contentBlock.fallbackSetting.prArticle"
                  id="contentBlock_setting_prArticle"
                  :label="$t('contentBlock.pr')"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <div class="form-group">
                <app-input
                  type="number"
                  v-model="contentBlock.daysLimit"
                  id="contentBlock_daysLimit"
                  :label="$t('contentBlock.daysLimit')"
                  :disabled="disabled"
                >
                </app-input>
              </div>
            </div>
          </div>
          <div class="col-lg-6" v-if="contentBlock.type === contentBlockTypes.video">
            <div class="card card-body">
              <h3 class="form-edit-header">{{ $t('contentBlock.fallback_settings') }}</h3>
              <div class="form-group">
                <app-checkbox
                  v-model="contentBlock.internalFallback"
                  id="contentBlock_internalFallback"
                  :label="$t('contentBlock.internal_fallback')"
                  :disabled="disabled"
                >
                </app-checkbox>
              </div>
              <app-select
                v-model="contentBlock.fallbackSite"
                :options="sites"
                id="contentBlock_falbackSite"
                :label="$t('contentBlock.fallback_site')"
                :disabled="disabled"
              >
              </app-select>
            </div>
          </div>
        </div>
      </section>
    </template>
  </app-module-view>
</template>

<script>
import { mapGetters } from 'vuex'
import ModuleView from '../../components/ModuleView'
import ContentBlockTypeMixin from '../../components/mixins/valueObject/ContentBlockTypeMixin'
import ContentBlockModel from '../../model/ContentBlock'
import Input from '../../components/form/inputs/Input'
import Checkbox from '../../components/form/Checkbox'
import Select from '../../components/form/select/Select'
import MultiSelect from '../../components/form/select/MultiSelect'
import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import ButtonDelete from '../../components/shared/ButtonDelete'
import ButtonClose from '../../components/shared/ButtonClose'
import NotifyService from '../../services/NotifyService'
import { AVAILABLE_TYPES } from '@/model/ValueObject/ContentBlockCategories'
import PermissionService from '@/services/PermissionService'
import { MODULE_SYSTEM_CONTENT_BLOCK } from '@/model/ValueObject/UserPermissionModules'

export default {
  name: 'ContentBlockNewView',
  mixins: [ContentBlockTypeMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tags: [],
      dataLoaded: false,
      isLoading: false,
      selectedTags: [],
      contentBlock: this._.cloneDeep(ContentBlockModel),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_CONTENT_BLOCK_PERMISSIONS
    }
  },
  computed: {
    ...mapGetters(['vlm']),
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isDeleteButtonVisible () {
      return this.contentBlock?.id && this.hasPermission(this.requiredPermissions.deleteButton)
    },
    isSaveButtonVisible () {
      return !this.disabled && this.hasPermission(this.requiredPermissions.saveButton)
    },
    isEditButtonVisible () {
      return this.disabled && this.hasPermission(this.requiredPermissions.editButton)
    },
    sites () {
      return this.$store.getters['site/enabledSitesForForm'](MODULE_SYSTEM_CONTENT_BLOCK, this.contentBlock, this.disabled)
    },
    rubrics () {
      return this.$store.getters['rubric/rubricsWithSite']
    },
    categories () {
      return this.$store.getters['category/all']
    },
    contentBlockCategories () {
      return AVAILABLE_TYPES
    }
  },
  validations: {
    contentBlock: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(250)
      },
      identifier: {
        required,
        minLength: minLength(3),
        maxLength: maxLength
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appInput: Input,
    appButtonDelete: ButtonDelete,
    appButtonClose: ButtonClose,
    appCheckbox: Checkbox,
    appSelect: Select,
    appMultiSelect: MultiSelect
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission, this.contentBlock.site)
    },
    rubricTitleById (id) {
      return this.$store.getters['rubric/rubricById'](id)?.title ?? id
    },
    categoryTitleById (id) {
      return this.categories.find(category => category.id === id)?.title ?? id
    },
    tagTitleById (id) {
      return this.selectedTags.find(tag => tag.id === id)?.title ?? id
    },
    onFallbackTagsInput (value) {
      this.contentBlock.fallbackTags = value ? value.map(v => v.id) : []
      this.selectedTags = [...value]
    },
    async loadMainRubricsBySite (siteId) {
      await this.$store.dispatch('rubric/fetchRubricsBySiteId', siteId)
    },
    getContentBlock () {
      this.$store.dispatch('contentBlock/fetchOne', this.$route.params.id)
        .then(async () => {
          this.contentBlock = this.$store.getters['contentBlock/detail']
          if (!this.$store.getters['user/hasPermission'](this.requiredPermissions.editRoute, this.contentBlock.site)) {
            this.$router.push(`/contentBlock/${this.contentBlock.id}`)
          } else {
            await this.loadMainRubricsBySite(this.contentBlock.fallbackSite)
            await this.$store.dispatch('rubric/lazyLoadRubricsByIds', this.contentBlock.fallbackRubrics)
            await this.$store.dispatch('tag/loadTagsByIds', this.contentBlock.fallbackTags)
            this.tags = this.$store.getters['tag/list']
            this.selectedTags = [...this.tags]
          }
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('contentBlock/create', this.contentBlock)
        .then(newId => {
          if (this.$store.getters['contentBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_created'))
            this.goToEdit(newId.id)
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlock/error'])
          }
        })
        .catch(error => console.log(error))
    },
    goToEdit (id) {
      return this.$router.push(`/contentBlock/${id ?? this.contentBlock.id}/edit`)
    },
    deleteRecord () {
      this.$store.dispatch('contentBlock/deleteRecord', this.contentBlock)
        .then(() => {
          if (this.$store.getters['contentBlock/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/contentBlock')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['contentBlock/error'])
          }
        })
        .catch(error => console.log(error))
    },
    findTag (query) {
      this.isLoading = true
      this.$store.dispatch('tag/fetchByTitle', { query })
        .then(() => {
          this.tags = this.$store.getters['tag/list']
          this.isLoading = false
        })
    }
  }
}
</script>
